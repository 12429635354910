<template>
	<div>
		<v-card
			v-if="!loading"
			:tile="tile"
			:outlined="outlined"
			:flat="flat"
			class="v-card--material px-5 py-3">
			<card-heading
				v-if="title"
				:color="color"
				:title="title">
				<slot />
			</card-heading>
			<v-card-text :class="stretch">
				<template v-for="(row, index) in rows">
					<template v-if="row.hide != true">
						<v-list-item :key="row.key">
							<v-list-item-content class="text-body-1">
								{{ row.key }}:
							</v-list-item-content>
							<v-list-item-content
								v-if="row.value !== null || row.value !== undefined"
								class="text-body-2 align-end text-end">
								<span v-if="row.format == undefined">
									{{ row.value }}
								</span>
								<span v-else>
									{{ formatMap[row.format](row.value) }}
								</span>
							</v-list-item-content>
						</v-list-item>
						<v-divider
							v-bind:key="index"
							v-if="index < rows.length - 1"></v-divider>
					</template>
				</template>
			</v-card-text>
		</v-card>
		<v-card
			v-else
			:tile="tile"
			:outlined="outlined"
			:flat="flat">
			<v-skeleton-loader type="card" />
		</v-card>
	</div>
</template>

<script>
import { format } from "date-fns";
import CardHeading from "./CardHeading.vue";

export default {
	components: { CardHeading },
	props: {
		title: {
			type: String,
			default: undefined,
		},
		rows: {
			type: Array,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		tile: {
			type: Boolean,
			default: false,
		},
		outlined: {
			type: Boolean,
			default: false,
		},
		flat: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: "secondary",
		},
		stretch: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			formatMap: {
				credits: this.formatCredits,
				percentage: this.formatPercentage,
				decimal: this.formatDecimal,
				date: this.formatDate,
				beautify: this.beautify,
			},
		};
	},
	methods: {
		formatCredits(n) {
			let money = n / 100;
			let currency = this.$store.getters["sessionDetail"]["data"]["currency"];
			return money + " " + (n + currency ? currency : "in credits");
		},

		formatPercentage(n) {
			return `${n}%`;
		},

		formatDecimal(n) {
			return n?.toString()?.match(/^\d*(\.\d{0,2})?/)?.[0];
		},

		formatDate(dateString) {
			if (!dateString) {
				return "";
			} else {
				return format(new Date(dateString), "dd/MM/yyyy HH:mm:ss");
			}
		},

		beautify(str) {
			return str;
		},
	},
};
</script>

<style></style>
