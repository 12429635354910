<template>
	<v-container
		id="BonusConfigDetail"
		fluid
		tag="section">
		<v-row dense>
			<v-col class="mr-auto ml-auto">
				<key-value-card
					title="Joker Bonus Configuration"
					:loading="jokerBonusConfigData.isLoading"
					:rows="jokerBonusConfig"
					:color="color"
					:header="dataHeaders">
					<div class="d-inline-flex">
						<v-tooltip
							top
							color="primary">
							<template #activator="{ on, attrs }">
								<v-btn
									color="primary"
									elevation="1"
									fab
									small
									class="mr-2"
									v-bind="attrs"
									v-on="on"
									@click="exportConfig">
									<v-icon>mdi-arrow-down-bold</v-icon>
								</v-btn>
							</template>
							<span>Export</span>
						</v-tooltip>

						<EditJokerBonusConfig
							:editJokerBonusConfigData="jokerBonusConfigCopy"
							:color="JB_configColor"
							:jokerBonusContribType="contribType"
							:allowEditOfPreMadeConfig="true"
							@submit="onJBConfigEditSubmit"
							v-if="
								_usrFlagsSome({
									key: 'bonus.group_configs.detail',
									val: permissions.EDIT,
								})
							">
							<template v-slot:button="{ on, attrs }">
								<v-btn
									color="primary"
									elevation="1"
									fab
									small
									v-bind="attrs"
									v-on="on"
									@click="openJBEdit">
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>
						</EditJokerBonusConfig>

						<v-dialog
							v-if="jackpotConfigDetail.deleted == null"
							v-model="dialogDelete"
							max-width="500px">
							<template v-slot:activator="{ on: dialog, attrs }">
								<v-tooltip
									color="error darken-2"
									bottom
									v-if="
										_usrFlagsSome({
											key: 'bonus.group_configs.detail',
											val: permissions.DELETE,
										}) && jackpotConfigDetail.deleted == null
									">
									<template v-slot:activator="{ on: tooltip }">
										<span v-on="tooltip">
											<v-btn
												class="ml-2"
												fab
												small
												color="error"
												link
												v-on="dialog"
												v-bind="attrs">
												<v-icon>mdi-close</v-icon>
											</v-btn>
										</span>
									</template>
									<span>Delete</span>
								</v-tooltip>
							</template>
							<v-card>
								<v-toolbar class="red d-flex white--text font-weight-bold">
									Delete
								</v-toolbar>
								<v-card-text>
									Are you sure you want to delete this Bonus Configuration?
								</v-card-text>
								<v-card-actions class="justify-space-between">
									<v-btn
										text
										@click="closeDelete">
										NO
									</v-btn>

									<v-btn
										color="error"
										text
										@click="handleDeactivateButton">
										YES
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>

						<v-dialog
							v-else
							v-model="dialogReactivate"
							max-width="500px">
							<template v-slot:activator="{ on: dialog, attrs }">
								<v-tooltip
									color="error darken-2"
									bottom
									v-if="
										_usrFlagsSome({
											key: 'bonus.group_configs.detail',
											val: permissions.DELETE,
										}) && jackpotConfigDetail.deleted !== null
									">
									<template v-slot:activator="{ on: tooltip }">
										<span v-on="tooltip">
											<v-btn
												class="ml-2"
												fab
												small
												color="error"
												link
												v-on="dialog"
												v-bind="attrs">
												<v-icon>mdi-restore</v-icon>
											</v-btn>
										</span>
									</template>
									<span>Restore</span>
								</v-tooltip>
							</template>

							<v-card>
								<v-toolbar class="red d-flex white--text font-weight-bold">
									Restore
								</v-toolbar>
								<v-card-text>
									Are you sure you want to restore this Bonus Configuration?
								</v-card-text>
								<v-card-actions class="justify-space-between">
									<v-btn
										text
										@click="closeReactivate">
										NO
									</v-btn>

									<v-btn
										color="error"
										text
										@click="handleReactivateButton">
										YES
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</div>
				</key-value-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import KeyValueCard from "../../components/shared/KeyValueCard";
import EditJokerBonusConfig from "../../components/jackpots/jackpot/EditJokerBonusConfig.vue";
import { CONTRIB_TYPES } from "../../constants/contribTypes";
import permissions from "../../mixins/permissions";
import { saveAsFile } from "../../constants/helpers";

export default {
	components: {
		KeyValueCard,
		EditJokerBonusConfig,
	},
	mixins: [permissions],

	data() {
		return {
			jokerBonusConfigCopy: {},
			jokerBonusConfigData: {},
			jokerBonusListFilteredByConfId: [],
			groupCurrency: "in groups currency",
			groupContribType: "",
			color: "secondary",
			JB_configColor: "secondary",

			dialogDelete: false,
			dialogReactivate: false,
			dataHeaders: [
				{
					sortable: false,
					text: "KEY",
					value: "key",
				},
				{
					sortable: false,
					text: "VALUE",
					value: "value",
				},
			],
		};
	},

	computed: {
		...mapGetters("jackpotConfig2", {
			jackpotConfigDetail: "detail",
		}),
		...mapGetters([
			"getJBConfigDetail",
			"filteredJokerBonusList",
			"updatedJBConfigDetail",
		]),
		contribType() {
			if (this.jackpotConfigDetail?.contrib_ratio !== null) {
				return CONTRIB_TYPES.PERCENT;
			}
			if (this.jackpotConfigDetail?.contrib_bet_range !== null) {
				return CONTRIB_TYPES.BET;
			}

			return undefined;
		},
		jokerBonusConfig() {
			return [
				{ key: "Id", value: this.jackpotConfigDetail.id },
				{
					key: "Contrib Ratio",
					value: this.jackpotConfigDetail.contrib_ratio,
					format: "percentage",
					hide: this.jackpotConfigDetail.contrib_ratio == null,
				},
				{
					key: "Contrib Bet Range",
					value: this.formatBetRange(
						this.jackpotConfigDetail.contrib_bet_range
					),
					hide: this.jackpotConfigDetail.contrib_bet_range == null,
				},
				{
					key: "Min Value",
					value: this.jackpotConfigDetail.min_value,
					// format: "credits",
					currency: this.groupCurrency,
				},
				{
					key: "Min Bonus",
					value: this.jackpotConfigDetail.min_jack,
					// format: "credits",
					currency: this.groupCurrency,
				},
				{
					key: "Max Value",
					value: this.jackpotConfigDetail.max_value,
					// format: "credits",
					currency: this.groupCurrency,
				},
				{
					key: "Bonus Type",
					value:
						this.jackpotConfigDetail.jackpot_type_id == 1
							? "Predetermined"
							: "Probabilistic",
					format: "beautify",
				},
				{
					key: "Distribution",
					value:
						this.jackpotConfigDetail.distribution_id == 1
							? "Beta"
							: "K-Uniform",
					format: "beautify",
				},
				{
					key: "K",
					value: this.jackpotConfigDetail.k,
					format: "decimal",
					hide: this.jackpotConfigDetail.distribution != "k-uniform",
				},
				{
					key: "Mean",
					value: this.jackpotConfigDetail.mean,
					// format: "credits",
					currency: this.groupCurrency,
					hide: this.jackpotConfigDetail.distribution != "beta",
				},
				{
					key: "Variance",
					value: this.jackpotConfigDetail.variance,
					format: "decimal",
					hide: this.jackpotConfigDetail.distribution != "beta",
				},
				{ key: "Count", value: this.jackpotConfigDetail.count },
				{
					key: "Shares",
					value: this.formatShares(this.jackpotConfigDetail.shares),
				},
				{
					key: "Selection",
					value: this.jackpotConfigDetail.selection_id == 1 ? "Random" : "Last",
					format: "beautify",
				},
				{
					key: "Candidates",
					value:
						this.jackpotConfigDetail.candidates_id == 1 ? "All" : "Contributed",
					format: "beautify",
				},
				{
					key: "Probability",
					value:
						this.jackpotConfigDetail.probability_id == 1 ? "Even" : "Weighted",
					format: "beautify",
				},
				{
					key: "Trim",
					value: this.jackpotConfigDetail.trim,
					format: "percentage",
				},
				{
					key: "Min Contribution",
					value: this.jackpotConfigDetail.min_contribution,
					format: "decimal",
				},
				{
					key: "Active Time",
					value: this.jackpotConfigDetail.active_time + "s",
				},
			];
		},
	},

	methods: {
		...mapActions("jackpotConfig2", {
			loadJackpotConfigDetail: "loadDetail",
			updateJackpotConfig: "update",
			deleteItem: "delete",
			restoreItem: "unDelete",
		}),
		...mapActions([
			"setBreadCrumbs",
			"loadJBConfigDetail",
			"updateJBConfig",
			"deactivateJBConfig",
			"loadFilteredJokerBonusList",
		]),
		saveAsFile,
		exportConfig() {
			const { created, updated, deleted, id, name, ...tmpExport } =
				this.jackpotConfigDetail;
			this.saveAsFile(tmpExport, name);
		},
		closeReactivate() {
			this.dialogReactivate = false;
		},
		closeDelete() {
			this.dialogDelete = false;
		},
		formatBetRange(arr) {
			if (arr) {
				return arr.join(" - ");
			} else {
				return undefined;
			}
		},

		formatShares(arr) {
			if (arr) {
				return arr.map((el) => el + "%").join(" - ");
			} else {
				return undefined;
			}
		},

		handleBackButton() {
			this.$router.push({ name: "bonus-bonus-configs", params: {} });
		},

		async handleDeactivateButton() {
			this.closeDelete();
			await this.deleteItem(this.jackpotConfigDetail.id);
			this.loadJackpotConfigDetail(this.$route.params.bonus_config_id);
		},
		async handleReactivateButton() {
			this.closeReactivate();
			await this.restoreItem(this.jackpotConfigDetail.id);
			this.loadJackpotConfigDetail(this.$route.params.bonus_config_id);
		},
		async openJBEdit() {
			this.jokerBonusConfigCopy = { ...this.jackpotConfigDetail };
		},
		async onJBConfigEditSubmit(e) {
			await this.updateJackpotConfig({
				id: this.jackpotConfigDetail.id,
				data: e,
			});
			await this.loadJackpotConfigDetail(this.$route.params.bonus_config_id);
		},
	},

	async created() {
		await this.loadJackpotConfigDetail(this.$route.params.bonus_config_id);
		this.setBreadCrumbs({
			pageName: this.jackpotConfigDetail.name,
			pageId: this.jackpotConfigDetail.id,
		});
	},
};
</script>
