var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? _c(
            "v-card",
            {
              staticClass: "v-card--material px-5 py-3",
              attrs: { tile: _vm.tile, outlined: _vm.outlined, flat: _vm.flat },
            },
            [
              _vm.title
                ? _c(
                    "card-heading",
                    { attrs: { color: _vm.color, title: _vm.title } },
                    [_vm._t("default")],
                    2
                  )
                : _vm._e(),
              _c(
                "v-card-text",
                { class: _vm.stretch },
                [
                  _vm._l(_vm.rows, function (row, index) {
                    return [
                      row.hide != true
                        ? [
                            _c(
                              "v-list-item",
                              { key: row.key },
                              [
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "text-body-1" },
                                  [_vm._v(" " + _vm._s(row.key) + ": ")]
                                ),
                                row.value !== null || row.value !== undefined
                                  ? _c(
                                      "v-list-item-content",
                                      {
                                        staticClass:
                                          "text-body-2 align-end text-end",
                                      },
                                      [
                                        row.format == undefined
                                          ? _c("span", [
                                              _vm._v(
                                                " " + _vm._s(row.value) + " "
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatMap[row.format](
                                                      row.value
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            index < _vm.rows.length - 1
                              ? _c("v-divider", { key: index })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "v-card",
            {
              attrs: { tile: _vm.tile, outlined: _vm.outlined, flat: _vm.flat },
            },
            [_c("v-skeleton-loader", { attrs: { type: "card" } })],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }